<template>
  <div class="min-h-screen">
    <div
      class="absolute top-0 flex items-center justify-center w-full py-4 text-white bg-gray-type-1"
      style="height: 72px"
    >
      <router-link to="/">
        <img src="@/assets/img/logo.svg" alt="Shuttlers" />
      </router-link>
    </div>
    <div class="flex flex-col items-center justify-center min-h-screen">
      <template v-if="!isSuccessful">
        <form class="w-full max-w-xs">
          <h2 class="text-2xl font-bold text-center text-dark-type-3">
            Create a new password
          </h2>
          <div class="mt-1 text-xs font-medium text-center text-dark-type-4">
            Try using something you can easily remember
          </div>
          <div
            class="flex items-start w-full p-3 mx-auto my-5 bg-red-200 rounded-md"
            v-if="errorMessage"
          >
            <div class="text-sm font-medium text-red-500">
              {{ errorMessage }}
            </div>
          </div>
          <div class="mt-12">
            <div class="mt-2">
              <label class="text-xs font-medium text-gray-type-3" for="password"
                >Password</label
              >
              <div class="relative mt-2">
                <input
                  v-model="$v.form.password.$model"
                  :type="showPassword ? 'text' : 'password'"
                  id="password"
                  class="w-full h-12 px-3 py-4 text-xs font-medium outline-none placeholder-gray-type-3 bg-gray-type-4 focus:outline-none"
                />
                <div
                  class="absolute inset-y-0 right-0 flex items-center pr-2 text-xs font-medium cursor-pointer text-green-type-1"
                  @click="showPassword = !showPassword"
                >
                  {{ showPassword ? "Hide" : "Show" }}
                </div>
              </div>
              <span
                class="text-xs font-medium text-red-400"
                v-if="$v.form.password.required.$invalid"
                >Please enter a password</span
              >
              <span
                class="text-xs font-medium text-red-400"
                v-if="
                  form.password.length < 6
                "
              >
                Password must have at least
                6 characters.
              </span>
            </div>
            <div class="mt-2">
              <label class="text-xs font-medium text-gray-type-3" for="password"
                >Confirm Password</label
              >
              <div class="relative mt-2">
                <input
                  v-model="$v.form.confirm_password.$model"
                  :type="showPassword ? 'text' : 'password'"
                  id="confirm-password"
                  class="w-full h-12 px-3 py-4 text-xs font-medium outline-none placeholder-gray-type-3 bg-gray-type-4 focus:outline-none"
                />
                <span
                  class="text-xs font-medium text-red-400"
                  v-if="$v.form.confirm_password.required.$invalid"
                  >Please enter a password</span
                >
                <span
                  class="text-xs font-medium text-red-400"
                  v-if="
                    form.confirm_password.length < 6
                  "
                >
                  Password must have at least
                  6
                  characters.
                </span>
                <span
                  class="text-xs font-medium text-red-400"
                  v-if="
                    !$v.form.confirm_password.required.$invalid &&
                    form.password != form.confirm_password
                  "
                >
                  Passwords do not match
                </span>
              </div>
            </div>
          </div>
          <app-button
            type="submit"
            class="justify-center block w-full h-12 py-4 mt-12 font-bold text-center text-white rounded focus:outline-none bg-green-type-1"
            :loading="processing"
            @click="resetPassword"
          >
            Set Password
          </app-button>
        </form>
      </template>
      <template v-else>
        <div class="w-full max-w-sm">
          <div class="flex items-center justify-center ml-20">
            <img
              src="@/assets/img/password-changed.svg"
              alt="Password changed"
            />
          </div>
          <h2 class="mt-4 text-2xl font-bold text-center text-dark-type-3">
            Password changed
          </h2>
          <div class="mt-1 text-sm font-medium text-center text-dark-type-4">
            You have successfully changed your password
          </div>
          <router-link
            to="/login"
            class="block w-56 h-12 py-4 mx-auto mt-12 font-bold text-center text-white rounded bg-green-type-1"
            >Login</router-link
          >
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import axios from "@/services/axios";
import { required, minLength } from "@vuelidate/validators";

export default {
  props: ["token"],
  data() {
    return {
      isSuccessful: false,
      showPassword: false,
      processing: false,
      errorMessage: "",
      form: {
        password: "",
        confirm_password: "",
        type: this.$route.query.type,
        token: this.token,
      },
    };
  },
  validations() {
    return {
      form: {
        password: {
          required,
          minLength: minLength(6),
        },
        confirm_password: {
          required,
          minLength: minLength(6),
        },
      },
    };
  },
  methods: {
    resetPassword() {
      this.$v.form.$touch();

      if (this.processing || this.$v.form.$errors.length) {
        return;
      }

      if (this.form.password !== this.form.confirm_password) {
        this.isSuccessful = false;
        this.errorMessage = "Passwords do not match";

        return;
      }

      this.processing = true;
      this.errorMessage = null;

      axios
        .post("/password/change", { ...this.form})
        .then(async () => {
          this.isSuccessful = true;
        })
        .catch((err) => {
          this.isSuccessful = false;

          if (err.response && err.response.data.message) {
            this.errorMessage = err.response.data.message;
          } else {
            this.errorMessage = "Oops! An error occured, please try again.";
          }
        })
        .finally(() => (this.processing = false));
    },
  },
};
</script>